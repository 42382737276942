import React, {useContext, useEffect, useState} from 'react';
import { PageContext } from '../Context/pageContext';
import {Button} from "@lxlabs/faze-screener-components-library";

import {
  FooterStyles,
  FooterContainer,
  ButtonStyled,
  Nav,
  ScrollUpBtn
} from './Footer/styles.js';

const FooterController = (props, hideLanguageSwitcher) => {
  const pageContext = useContext(PageContext);
  let isRequired = false;
  let contentCopy;

  try {
    contentCopy = require(`../../data/navigation/${pageContext.language}.js`);
  } catch (e) {
    contentCopy = require(`../../data/navigation/country-selector-page.js`);
  }

  const nav = contentCopy.FooterNav.links;
  let apply = contentCopy.FooterNav.apply;
  let languageSwitch = contentCopy.LanguageSwitchButton.languageSwitch;
  let footerBottom = contentCopy.FooterBottom;
  let footerButton = contentCopy.FooterButton;
  let hideNav = props.hideNav;
  if(hideNav === undefined){
    hideNav = false;
  }
  if (props.isHCP) {
    footerBottom = contentCopy.FooterBottomHCP;
  }

  hideLanguageSwitcher = languageSwitch.isHidden;

  const [disableScrollArrow, setDisableScrollArrow] = useState(true);

  useEffect(() => {

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      let documentHeight = window.document.body.offsetHeight;

      if(currentScrollY > (documentHeight / 4)){
        setDisableScrollArrow(false)
      }else{
        setDisableScrollArrow(true)
      }

    };

    window.addEventListener("scroll", handleScroll, {passive: true});


  }, []);


  if(props.button === false){
    apply = {}
  }

  let customClass = props.addLowerMargin === true ? 'screener-footer' : '';
  customClass = props.className ?? customClass;

  const ScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <FooterStyles className={customClass}>
        <ScrollUpBtn className={disableScrollArrow ? 'disabled' : ''} data-cy="scroll-to-top-button" onClick={() => {
          ScrollUp();
        }}>
          <img src={'/static-images/arrow-chevron-up.png'} />
        </ScrollUpBtn>

        {!hideNav &&
          <>
            <FooterContainer>
              <>
                <Nav>{nav.map((link, i) => (<a data-cy={"footer_nav-link-"+i} key={i} href={link.to} className={pageContext.currentPage === link.to ? 'active' : ''}>{link.title}</a>))}</Nav>
                {isRequired ? footerButton.map((atts) => <Button label={atts.btnTitle} href={atts.to}/>) : null}



                {!hideLanguageSwitcher && apply.to && apply.title &&

                  <>
                    <hr className={'divider'}/>

                    <ButtonStyled
                      className={'language-switch-btn'}
                      type={'outline'}
                      size={'fill'}
                      id={'country-menu-button'}
                      ariaLabel={'Country and language selector'}
                      ariaControls={'country-menu'}
                      ariaHaspopup={true}
                      cyAttribute={'country-menu-open'}
                      label={languageSwitch.title}
                      href={pageContext.languageSwitchPath}
                    />
                  </>
                }


                {!props.hideSignup && apply && apply.to && apply.title &&
                  <Button
                    cyAttribute={'footer_sign-up-now-button'}
                    className={'apply-now-btn'}
                    href={apply.to}
                    size={'fill'}
                    label={apply.title}
                  />
                }
              </>
            </FooterContainer>
            <hr />
          </>
        }
        <FooterContainer columnReverse>
          { footerBottom.leftSideLinks &&
            footerBottom.leftSideLinks.map((link, i) => (
              <div className="report" key={i}>{link.title}
                <a href={link.to} data-cy={link.cyAttribute} target={link.target} rel={link.rel}>{link.linkCopy}</a>
              </div>
            ))
          }
          {
            footerBottom.rightSideLinks &&
            <div className='legal-links'>
              {footerBottom.rightSideLinks.map((link, i) => (
                <a key={i} href={link.to} data-cy={link.cyAttribute} target={link.target} rel={link.rel}>{link.title}</a>
              ))}
            </div>
          }
        </FooterContainer>
      </FooterStyles>
    </>
  );
};

export default FooterController;

