export const isRequired = false;

export const Config = {
    logo: 'combined'
}

export const FooterNav = {
    links: [
        {
            title: 'Página de inicio',
            to: '/es-US/'
        },
        {
            title: 'Ensayo SUPERNOVA',
            to: '/es-US/ensayosupernova'
        },
        {
            title: 'Acerca de los ensayos clínicos',
            to: '/es-US/acercadelosensayosclinicos'
        },
        {
            title: 'Acerca de la COVID-19',
            to: '/es-US/sobrelacovid19'
        },
        {
            title: 'Preguntas frecuentes',
            to: '/es-US/preguntasfrecuentes'
        },
    ],
    apply: {
        to: '/es-US/ensayocerrado',
        title: 'Inscríbase ahora'
    }
};

export const LanguageSwitchButton = {
    languageSwitch: {
        title: 'Idioma: Español',
        isHidden: true
    }
}

export const FooterButton = [
    {
        btnTitle: 'Inscríbase ahora',
        to:'/es-US/ensayocerrado'
    }
];
export const FooterBottom = {
    leftSideLinks: [
        {
            title: 'Puede informar sobre efectos secundarios relacionados con los productos de AstraZeneca haciendo clic',
            linkCopy: 'aquí',
            to: 'https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html',
            cyAttribute: 'footer_side-effects-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
    ],
    rightSideLinks: [
         // {
        //     title: 'Contáctenos',
        //     to: '',
        //     cyAttribute: 'footer_contact-us-link',
        //     target: '_blank',
        //     rel: 'noopener noreferrer'
        // },
        // {
        //     title: 'Aviso legal',
        //     to: '',
        //     cyAttribute: 'footer_legal-notice-link',
        //     target: '_blank',
        //     rel: 'noopener noreferrer'
        // },
        {
            title: 'Política de privacidad',
            to: '/es-US/politicadeprivacidad',
            cyAttribute: 'footer_privacy-policy-link',
            target: '',
            rel: ''
        }
    ]
}

export const HeaderNavigation = {
    links: [
        {
            title: 'Ensayo Supernova',
            to: '/es-US/ensayosupernova'
        },
        {
            title: 'Acerca de los ensayos clínicos',
            to: '/es-US/acercadelosensayosclinicos'
        },
        {
            title: 'Acerca de la COVID-19',
            to: '/es-US/sobrelacovid19'
        },
        {
            title: 'Preguntas frecuentes',
            to: '/es-US/preguntasfrecuentes'
        }
    ],
    apply: {
        to: '/es-US/ensayocerrado',
        title: 'Inscríbase ahora'
    },
    menu: 'MENÚ',
    lang: 'ES',
}