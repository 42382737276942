export const isRequired = false;

export const Config = {
    logo: 'combined'
}

export const FooterNav = {
    links: [],
    apply: {},
};

export const LanguageSwitchButton = {
    languageSwitch: {
        title: '',
        isHidden: true
    }
}

export const FooterButton = [
    {
        btnTitle: '',
        to:''
    }
];
export const FooterBottom = {
    leftSideLinks: [
        {
            title: 'You may report side effects related to AstraZeneca products by clicking',
            linkCopy: 'here',
            to: 'https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html',
            cyAttribute: 'footer_side-effects-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
    ],
    rightSideLinks: [
        {
            title: 'Privacy Notice',
            to: 'https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html',
            cyAttribute: 'footer_privacy-policy-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
        {
            title: 'Legal Notice',
            to: 'https://www.astrazeneca.com/legal-notice-and-terms-of-use.html',
            cyAttribute: 'footer_legal-notice-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
        {
            title: 'Contact Us',
            to: 'https://www.astrazeneca.com/our-company/contact-us.html',
            cyAttribute: 'footer_contact-us-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        }
    ]
}