export const isRequired = false;

export const Config = {
    logo: 'combined'
}

export const FooterNav = {
    links: [
        {
            title: 'Homepage',
            to: '/en-US/'
        },
        {
            title: 'SUPERNOVA Trial',
            to: '/en-US/supernovatrial'
        },
        {
            title: 'About clinical trials',
            to: '/en-US/aboutclinicaltrials'
        },
        {
            title: 'About COVID-19',
            to: '/en-US/aboutcovid19'
        },
        {
            title: 'FAQs',
            to: '/en-US/faqs'
        },
    ],
    apply: {
        to: '/en-US/trialclosed',
        title: 'Sign Up Now'
    }
};

export const LanguageSwitchButton = {
    languageSwitch: {
        title: 'Idioma: Español',
        isHidden: true
    }
}

export const FooterButton = [
    {
        btnTitle: 'Sign Up Now',
        to:'/en-US/trialclosed'
    }
];
export const FooterBottom = {
    leftSideLinks: [
        {
            title: 'You may report side effects related to AstraZeneca products by clicking',
            linkCopy: 'here',
            to: 'https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html',
            cyAttribute: 'footer_side-effects-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
    ],
    rightSideLinks: [
        {
            title: 'Contact Us',
            to: 'https://www.astrazeneca-us.com/az-in-us/Contact-us.html',
            cyAttribute: 'footer_contact-us-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
        {
            title: 'Legal Notice',
            to: 'https://www.astrazeneca-us.com/legal-notice.html',
            cyAttribute: 'footer_legal-notice-link',
            target: '_blank',
            rel: 'noopener noreferrer'
        },
        {
            title: 'Privacy Policy',
            to: '/en-US/privacypolicy',
            cyAttribute: 'footer_privacy-policy-link',
            target: '',
            rel: ''
        }
    ]
}

export const FooterBottomHCP = [
    {
        title: 'You may report side effects related to AstraZeneca products by clicking',
        linkTitle: 'here',
        to: 'https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html'
    },
    {
        title: 'Privacy Policy',
        to: '/en-US/privacypolicyhcp'
    }
];

export const HeaderNavigation = {
    links: [
        {
            title: 'Supernova Trial',
            to: '/en-US/supernovatrial'
        },
        {
            title: 'About clinical trials',
            to: '/en-US/aboutclinicaltrials'
        },
        {
            title: 'About COVID-19',
            to: '/en-US/aboutcovid19'
        },
        {
            title: 'FAQs',
            to: '/en-US/faqs'
        }
    ],
    apply: {
        to: '/en-US/trialclosed',
        title: 'Sign Up Now'
    },
    menu: 'MENU',
    lang: 'EN',
}

